import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import arrowback from "../assets/arrow_back_ios.png";
import robo from "../assets/robonew.webp";
import selo from "../assets/check.png";
import { ClipLoader } from "react-spinners";

const ContatoModal = ({ show, handleClose }) => {
  const [nameClient, setNameClient] = useState("");
  const [number, setNumber] = useState("");
  const [email, setEmail] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [utmParams, setUtmParams] = useState({
    utmCampaign: "",
    utmContent: "",
    utmMedium: "",
    utmSource: "",
    utmTerm: "",
  });
  const [currentId, setCurrentId] = useState("5513997020038");

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    setUtmParams({
      utmCampaign: urlParams.get("utm_campaign") || "",
      utmContent: urlParams.get("utm_content") || "",
      utmMedium: urlParams.get("utm_medium") || "",
      utmSource: urlParams.get("utm_source") || "",
      utmTerm: urlParams.get("utm_term") || "",
    });
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
  } = useForm();

  const handleCloseModal = () => {
    handleClose();
  };



  const handleSubmit2 = () => {
    if (!validatePhoneNumber(number)) {
      return;
    }

    setSubmitting(true)

    const dados = {
      channel: {
        type: "WHATSAPP",
        id: currentId,
      },
      id: number,
      name: nameClient,
      lead: true,
      fields: [
        { name: "email", value: email },
        { name: "fonte", value: process.env.REACT_APP_FONTE_LEAD },
        { name: "utmCampaign", value: utmParams.utmCampaign || "" },
        { name: "utmContent", value: utmParams.utmContent || "" },
        { name: "utmMedium", value: utmParams.utmMedium || "" },
        { name: "utmSource", value: utmParams.utmSource || "" },
        { name: "utmTerm", value: utmParams.utmTerm || "" },
        { name: "formSite", value: "Sim" },
      ],
    };

    fetch ("https://service.apidoc.com.br/api/megazap/subir-lead", {
      body: JSON.stringify(dados),
      method: "POST",
      headers: {
        "customer-token": "b7d92abd-f806-44be-8542-4ace008a751c",
        "Content-Type": "application/json",
      },
    }).then(
      (response) => {
        if (response.status === 200) {
          setSubmitting(false)
          Swal.fire({
            icon: "success",
            title: "Obrigado pelo contato! 😊",
            text: "Recebemos seu contato, estamos te redirecionando para o WhatsApp.",
            showConfirmButton: false,
            timer: 1000,
          });

          handleCloseModal();
          
          setTimeout(() => {
            setNameClient("");
            setNumber("");
            setEmail("");
            const message = encodeURIComponent(
              "Olá, gostaria de saber mais sobre a MegaZap!"
            );
            const redirectUrl = `https://wa.me/+${currentId}?text=${message}`;
            redirectToWhatsApp(redirectUrl);
            // Alterna o valor do currentId após o redirecionamento
            setCurrentId((prevId) =>
              prevId === "5513997020038" ? "551335007939" : "5513997020038"
            );
          }, 1000);
        } else {
          setNameClient("");
          setNumber("");
          setEmail("");
          const message = encodeURIComponent(
            "Olá, gostaria de saber mais sobre a MegaZap!"
          );
          const redirectUrl = `https://wa.me/+${currentId}?text=${message}`;
          redirectToWhatsApp(redirectUrl);
          // Alterna o valor do currentId após o redirecionamento
          setCurrentId((prevId) =>
            prevId === "5513997020038" ? "551335007939" : "5513997020038"
          );
        }
      },
      (err) => {
        
        console.log("FAILED...", err);
        setSubmitting(false);
        Swal.fire({
          icon: "error",
          title: "Algo deu errado, tente novamente 😢",
          showConfirmButton: false,
          timer: 1000,
        });
      }
    );
  };

  const validatePhoneNumber = (value) => {
    const phoneRegex = /^55\d{2}9\d{8}$/;
    if (!phoneRegex.test(value)) {
      setError("number", {
        type: "manual",
        message: "Número de telefone inválido.",
      });
      return false;
    }
    clearErrors("number");
    return true;
  };

  const redirectToWhatsApp = (url) => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      window.location.href = url;
    } else {
      window.open(url);
    }
  };

  return (
    <>
       {
  submitting && (
    <div className="fixed h-screen inset-0 flex items-center justify-center z-50 bg-[#c7c7c780] bg-opacity-30">
      <ClipLoader  size={50} color={"#123abc"} loading={submitting} />
    </div>
  )
}
      {show && (
        <div
          className="fixed inset-0 flex items-center z-40 justify-center"
         
        >
          <div
            className="fixed inset-0 bg-black  opacity-50"
            onClick={handleCloseModal}
          ></div>
          <div className="absolute bg-formbg  bg-[#EFEAE4] shadow-lg  z-0 rounded-xl">
            <div className=" flex h-[80px] rounded-t-xl justify-center items-center flex-col bg-[#0F5F54]">
              <div>
                <img
                  src={arrowback}
                  alt=""
                  onClick={handleCloseModal}
                  className="absolute cursor-pointer top-0 left-0 my-8 ml-4"
                />
              </div>
              <div className="absolute overflow-hidden flex rounded-full h-[58px] w-[58px] top-0 left-0 ml-16 my-3 bg-gradient-to-b bg-[#8DD8F9] linear-gradient(to bottom, var(--tw-gradient-stops));">
                <img
                  src={robo}
                  alt=""
                  className="z-40 justify-center items-center"
                />
              </div>
              <div className="flex justify-center items-center text-[#FFFFFF] ml-2">
                <h1 className=" text-[20px] font-bold">MegaZap</h1>
                <img src={selo} alt="" />
              </div>
            </div>
            {/* <span
              className="absolute top-0 right-0 m-4 cursor-pointer text-gray-600 border border-black"
              onClick={handleCloseModal}
            >
              ×
            </span> */}
            {/* <h2 className="text-xl font-bold mb-4 border border-black bg-black">
              Entre em Contato Conosco
            </h2> */}
            <form onSubmit={handleSubmit(handleSubmit2)} className="px-8 py-8">
              <div className="mb-4">
                <label
                  htmlFor="nameClient"
                  className="block text-sm font-medium text-gray-700"
                >
                  Nome:
                </label>
                <input
                  {...register("nameClient", {
                    required: "Ops, o nome é obrigatorio. 🚫",
                  })}
                  type="text"
                  name="nameClient"
                  id="nameClient"
                  autoComplete="nameClient"
                  placeholder="Digite seu nome"
                  onChange={(e) => setNameClient(e.target.value)}
                  value={nameClient}
                  required
                  className="mt-1 p-2 w-full border rounded-md"
                />
                {errors.nameClient && (
                  <span className="text-red-700 absolute">
                    {errors.nameClient.message}
                  </span>
                )}
              </div>
              {/* <div className="mb-4">
                <label
                  htmlFor="segmento"
                  className="block text-sm font-medium text-gray-700"
                >
                  Segmento de atuação:
                </label>
                <input
                  {...register("segmento", {
                    required: "Ops, o segmento de atuação é obrigatorio. 🚫",
                  })}
                  type="text"
                  name="segmento"
                  id="segmento"
                  autoComplete="segmento"
                  placeholder="Digite o segmento de atuação"
                  value={segmento}
                  required
                  className="mt-1 p-2 w-full border rounded-md"
                />
                {errors.segmento && (
                  <span className="text-red-700 absolute">
                    {errors.segmento.message}
                  </span>
                )}
              </div> */}
              <div className="mb-4">
                <label
                  htmlFor="contact"
                  className="block text-sm font-medium text-gray-700"
                >
                  WhatsApp:
                </label>
                <PhoneInput
                  country={"br"}
                  value={number}
                  onChange={(phone) => {
                    setNumber(phone);
                    validatePhoneNumber(phone);
                  }}
                  containerClass="mt-1 w-full"
                  inputClass="p-2 w-full border rounded-md"
                  autoFormat={true}
                  disableCountryCode={false}
                  placeholder="+55 (79) 9 9999-9999"
                  type="tel"
                />
                {errors.number && (
                  <span className="text-red-700 absolute">
                    {errors.number.message}
                  </span>
                )}
              </div>
              <div className="mb-4">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email:
                </label>
                <input
                  {...register("email", {
                    required: "Ops, email inválido. 🚫",
                  })}
                  type="email"
                  name="email"
                  id="email"
                  autoComplete="email"
                  placeholder="Digite seu E-mail mais ativo!"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  required
                  className="mt-1 p-2 w-full border rounded-md"
                />
                {errors.email && (
                  <span className="text-red-700 absolute">
                    {errors.email.message}
                  </span>
                )}
              </div>
              <button
                type="submit"
                className="bg-blue-500 text-white px-4 py-2 w-full rounded-md"
              >
                Enviar
              </button>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default ContatoModal;
