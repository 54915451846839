import React from "react";
import logomz from "../assets/minimz.webp";
import { FaWhatsapp } from "react-icons/fa";
import { FaSquareInstagram } from "react-icons/fa6";
import { FaLinkedin } from "react-icons/fa";
import { FaFacebookSquare } from "react-icons/fa";
import logoabf from "../assets/logoabf.svg"

const Footer = ({ openContatoModal }) => {
  return (
    <div className="bg-gray-100 dark:bg-slate-950 py-8  xx:px-44 mm:px-0  ]">
      <div className="container mx-auto mm:px-4 lg:px-[14%] ">
        <div className="flex flex-wrap md:flex-row mm:justify-center items-center lg:justify-between mm:gap-6 lg:gap-8">
          <div className="flex flex-col items-center md:items-start">
            <a href="/" className="">
              <img src={logomz} className="mm:h-20 lg:h-24 flex dark:hidden cursor-pointer" alt="logo" />
            </a>

          </div>
          <div>
            <img src={logoabf} className="mm:h-20" alt="logoabf" draggable="false" />
            </div>
          <div className="mt-2 flex flex-col items-center ">
            <div className="w-40 h-[34px] px-2 py-[5px] bg-[#0098D9] rounded-2xl shadow flex justify-center items-center gap-1">
              <FaWhatsapp className="w-4 h-4 text-white" />
              <div onClick={openContatoModal} className="text-white text-sm font-semibold cursor-pointer">Whatsapp MZ</div>
            </div>
            <p className="mt-2 text-[#00649C]  text-base font-medium">+55 (13) 3500-7939</p>
            <p className="mt-2 text-[#00649C]  text-base font-medium">contato@megazap.com</p>
            <a href="https://megazap.com/politica-de-privacidade">
              <p className="mt-2 text-[#00649C]  text-base font-medium">Politica de Privacidade</p>
            </a>
            <div className="flex space-x-4 mt-2">
              <a href="https://www.instagram.com/mz.megazap/" aria-label="abrir instagram" target="_blank" rel="noreferrer" className="text-[#828282] hover: ">
                <FaSquareInstagram size={24} />
              </a>
              <a href="https://www.linkedin.com/in/megazapbusiness/?originalSubdomain=br" aria-label="abrir linkedin" target="_blank" rel="noreferrer" className="text-[#828282] hover: ">
                <FaLinkedin size={24} />
              </a>
              <a href="https://www.facebook.com/megazapbusiness/" aria-label="abrir facebook" target="_blank" rel="noreferrer" className="text-[#828282] hover: ">
                <FaFacebookSquare size={24} />
              </a>
            </div>
          </div>
         
        </div>
      </div>
    </div>
  );
};

export default Footer;