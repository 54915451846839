import React, { useState, useEffect } from "react";
import logomz from "../assets/MZMEGAZAP.webp";
import minilogomz from "../assets/minimz.webp";
import { Link } from "react-scroll";
import { CgMenuGridR } from "react-icons/cg";
import winner from "../assets/logof.png";

const Header = ({ openContatoModal }) => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState("");
  const [isVisible, setIsVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const controlNavbar = () => {
    if (window.scrollY > lastScrollY) {
      setIsVisible(false);
    } else {
      setIsVisible(true);
    }
    setLastScrollY(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", controlNavbar);

    return () => {
      window.removeEventListener("scroll", controlNavbar);
    };
  }, [lastScrollY]);

  const handleMenuClick = (menu) => {
    setSelectedMenu(menu);
    setIsMenuOpen(false);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }

      if (isDropdownOpen) {
        setIsDropdownOpen(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isDropdownOpen]);

  return (
    <header
      className={` fixed z-30 mm:top-1 lg:top-5 left-0 right-0 font-Maven transition-all duration-500 ease-in-out ${
        isScrolled ? "max-w-screen-lg " : "max-w-screen-2xl"
      } mx-auto`}
    >
      <div
        className={` mm:py-px lg:py-1 bg-white transition-all duration-500 ease-in-out ${
          isScrolled ? "shadow-md rounded-[16px]" : "shadow-none"
        }`}
      >
        <div className="w-full h-16 dark:bg-slate-950 grid grid-cols-12 bg-red-40 justify-between items-center">
          {/* Logo do pc */}
          <div
            className={`lg:w-fit flex bg-orange-60 transition-all duration-300 ease-in-out ${
              isScrolled
                ? `mm:col-span-3 mm:col-start-2 lg:col-start-1 mm:pl-0 lg:pl-5 gx:col-span-2 justify-start`
                : ` mm:col-start-1 mm:pl-4 lg:pl-0 lg:col-start-2 mm:col-span-3 lg:col-span-1`
            }`}
          >
            <a href="/" className="">
              <img
                src={logomz}
                className="h-6 w-full hidden lg:flex cursor-pointer"
                alt="logo"
                width="auto"
                height="24"
              />
              <img
                src={minilogomz}
                className="h-10 flex lg:hidden cursor-pointer"
                alt="logo"
                width="auto"
                height="40"
              />
            </a>
          </div>
          <div className="col-span-6 lg:col-span-5 lg:col-start-4 gx:col-span-5 gx:col-start-4 xl:col-span-6 xl:col-start-4 mm:hidden lg:flex justify-center items-center xx:gap-10">
            <ul
              className={`flex flex-col lg:flex-row justify-between mm:h-auto lg:h-16 mm:items-start lg:items-center py-4 w-screen mm:bg-slate-100 lg:bg-inherit mm:dark:bg-slate-950 lg:dark:bg-inherit mm:pl-5 lg:pl-0 transition-all duration-300 ease-in-out ${
                isScrolled ? `` : ``
              }`}
            >
              <li
                className={`relative font-Inter_Normal cursor-pointer h-full flex items-center ${
                  selectedMenu === "Soluções"
                    ? "text-sky-600"
                    : "text-black dark:text-white"
                }`}
              >
                <Link
                  to="cards"
                  href="cards"
                  smooth={true}
                  duration={1000}
                  onClick={() => handleMenuClick("Soluções")}
                >
                  <span
                    className={`relative z-10 font-Inter_Regular justify-center   items-center h-10 text-[14px] font-normal leading-normal transition-all duration-300 ease-in-out ${
                      isScrolled ? `flex` : `flex`
                    }`}
                  >
                    A empresa
                  </span>
                </Link>
              </li>
              <li
                className={`relative font-Inter_Normal cursor-pointer h-full flex items-center ${
                  selectedMenu === "Parceiros"
                    ? "text-sky-600"
                    : "text-black dark:text-white"
                }`}
                onClick={() => handleMenuClick("Parceiros")}
              >
                <a
                  href="https://megazap.com/parceiros/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="relative z-10 font-Inter_Regular justify-center items-center flex h-10 text-[14px] font-normal">
                    Parceiros
                  </span>
                </a>
              </li>
              <li
                className={`relative font-Inter_Normal cursor-pointer h-full flex items-center ${
                  selectedMenu === "Planos"
                    ? "text-sky-600"
                    : "text-black dark:text-white"
                }`}
              >
                <Link
                  to="Planos"
                  href="Planos"
                  smooth={true}
                  duration={1000}
                  onClick={() => handleMenuClick("Planos")}
                >
                  <span className="relative z-10 font-Inter_Regular justify-center items-center flex h-10 text-[14px] font-normal">
                    Planos
                  </span>
                </Link>
              </li>

              <li
                className={`relative font-Inter_Normal cursor-pointer h-full flex items-center ${
                  selectedMenu === "Contato"
                    ? "text-sky-600"
                    : "text-black dark:text-white"
                }`}
              >
                <Link
                  to="FAQ"
                  href="FAQ"
                  smooth={true}
                  duration={1000}
                  onClick={() => handleMenuClick("Contato")}
                >
                  <span className="relative z-10 font-Inter_Regular mm:justify-start lg:justify-center items-center flex h-10 text-[14px] w-full font-normal">
                    Contato
                  </span>
                </Link>
              </li>
            </ul>
          </div>

          {/* Menu */}
          <div
            className={` font-Mont font-bold text-[14px] flex mm:justify-between lg:justify-end gap-x-4 transition-all duration-300 ease-in-out ${
              isScrolled
                ? `mm:col-start-5 lg:col-start-10 pr-4 mm:col-span-7 lg:col-span-3`
                : ` mm:col-start-5 lg:col-start-10 mm:col-span-8 pr-4 lg:col-span-2`
            } `}
          >
            <button
              onClick={openContatoModal}
              className="bg-[#0098D9] rounded-full text-white py-1 mm:px-8 lg:px-2"
            >
              SAIBA MAIS
            </button>
            <button onClick={toggleDropdown} aria-label="menu">
              <CgMenuGridR className="size-6 text-textGray dark:text-white" />
            </button>
          </div>
        </div>

        {/* Dropdown suspenso */}
        {isDropdownOpen && (
          <div
            className={`absolute  mm:top-[55px] lg:top-[100%] bg-gray-50 dark:bg-slate-800 shadow-lg mm:rounded-b-lg lg:rounded-lg px-4 py-2 mm:w-full lg:w-[300px] ${
              isScrolled ? `right-0` : ` mm:right-0 lg:right-[8%]`
            }`}
          >
            <div className="block lg:hidden ">
              <ul className="flex  flex-wrap gap-x-6 gap-y-2 text-center items-center justify-center">
                <li>A empresa</li>
                <li>Parceiros</li>
                <li>Planos</li>
                <li>Contato</li>
              </ul>
            </div>
            <div className="space-y-10">
              <div className="font-Inter_Normal font-bold text-[16px] leading-none mm:pt-8 lg:pt-0">
                Nossos produtos
              </div>
              <div className="flex items-center space-x-10">
               
                <img src={winner} alt="winnermz" className="h-10" />
              </div>
              <div className="flex w-full items-center justify-center">
                <a
                  href="https://megazap.chat/login.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  <button className="font-Mont font-medium text-[14px] rounded-full shadow-blueshadow py-1 px-4 text-[#00649C]">
                    Painel do Gestor
                  </button>
                </a>
              </div>
            </div>
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;
